.profile-pic {
  content: url("/img/profile_pic.png");
  margin: 0 auto;
  margin-top: 20px;
}

.profile-pic2 {
  content: url("/img/profile_pic2.jpg");
  height: 100px;
}

div.page-heading.about-heading {
  padding-bottom: 40px;
}
